import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: process.env.GASTBY_SENTRY_DSN,
    integrations: [new BrowserTracing({
        tracePropagationTargets: ["localhost", "dq3uu86quy22l.cloudfront.net", /^\//],
    })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.GASTBY_SENTRY_TRAKING_SAMPLE,
});
